import { JobType } from '../../../types';
import { updateState } from '../../models';
import { createToast } from '../../toasts.platform';
import { StateUpdateOptionsWithoutEventName } from '../../types';
import { queueJob } from './jobs';

export const updateImportEmailAddresses = async (
  nonce: string,
  options: StateUpdateOptionsWithoutEventName,
) => {
  await updateState(
    (state) => {
      if (!state.client.profile) {
        return;
      }

      const feedEmail = state.client.profile.custom_feed_email;
      const feedEmailAt = feedEmail?.slice(feedEmail.indexOf('@'));

      const libraryEmail = state.client.profile.custom_library_email;
      const libraryEmailAt = libraryEmail?.slice(libraryEmail.indexOf('@'));

      state.client.profile.custom_library_email = nonce + libraryEmailAt;
      state.client.profile.custom_feed_email = nonce + feedEmailAt;
    },
    { ...options, eventName: 'update-import-email-address' },
  );

  queueJob({
    jobType: JobType.UpdateImportEmailAddress,
    jobArguments: { nonce },
    options: { ...options },
  });

  createToast({
    content: `Import email address is updated`,
    category: 'success',
  });
};
